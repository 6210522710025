import { RawDraftContentState, RawDraftContentBlock, RawDraftEntity, genKey } from 'draft-js';

export function createRawContent(transcriptRows?: Transcript[] | null): RawDraftContentState | null {
  const blocks: Array<RawDraftContentBlock> = [];
  const entityMap: { [key: string]: RawDraftEntity } = {};

  if (transcriptRows && transcriptRows.length) {
    const transcripts = transcriptRows.map(transcriptRow => {
      return {
        ...transcriptRow,
        wordsJson: transcriptRow.words || [],
      };
    });

    transcripts.forEach((transcriptRow, rowIndex) => {
      let accumulattedOffset = 0;
      const inlineStyleRanges: any[] = [];

      transcriptRow.wordsJson.forEach((word: any, index: number) => {
        if (index) {
          const previousText = transcriptRow.wordsJson[index - 1].text;
          accumulattedOffset += previousText.length;
        }
        if (word.style) {
          word.style.forEach((s: string[]) => {
            inlineStyleRanges.push({
              offset: accumulattedOffset,
              length: word.text.length,
              style: s,
            });
          });
        }
      });

      accumulattedOffset = 0;

      const entityRanges = transcriptRow.wordsJson.map((word, index) => {
        // forced type conversion due to inaccuracy in RawDraftEntity definition
        const entityKey = (('_' + String(rowIndex) + '_' + String(index)) as unknown) as number;

        entityMap[entityKey] = {
          type: 'TIME_CODED_WORD',
          data: {
            startTime: word.startTime,
            endTime: word.endTime,
          },
          mutability: 'MUTABLE',
        };

        if (index) {
          const previousText = transcriptRow.wordsJson[index - 1].text;
          accumulattedOffset += previousText.length;
        }

        return {
          offset: accumulattedOffset,
          length: word.text.length,
          key: entityKey,
        };
      });
      blocks.push({
        text: transcriptRow.wordsJson.map(word => word.text).join(''),
        type: 'TranscriptRow',
        data: {
          ...transcriptRow,
          speaker: transcriptRow.speaker ? JSON.stringify(transcriptRow.speaker) : '',
          annotation: transcriptRow.annotation,
        },
        entityRanges,
        inlineStyleRanges: inlineStyleRanges,
        // key: String(rowIndex),
        key: genKey(),
        depth: 0,
      });
    });

    return { blocks, entityMap };
  }
  return null;
}
